import { Injectable } from "@angular/core";
import { LocalStorageService } from "./storage/local-storage.service";
import { AuthService } from "./auth/auth.service";

interface ZendeskWindow extends Window {
	zE: (
		type: string,
		action: string,
		callback?: (jwtFn: (jwt: string) => void) => unknown
	) => void;
}

@Injectable({
	providedIn: "root"
})
export class ZendeskService {
	constructor(
		private localStorageService: LocalStorageService,
		private window: Window,
		private authService: AuthService
	) {}

	initZendesk(): void {
		const zendeskJwt = this.localStorageService.get<string>("jwtZendesk");
		if (zendeskJwt) {
			const zendeskWindow = this.window as ZendeskWindow;
			zendeskWindow.zE("messenger", "show");
			zendeskWindow.zE("messenger", "loginUser", (callback) => {
				callback(zendeskJwt);
			});
		}
	}

	hideZendesk(): void {
		const zendeskWindow = this.window as ZendeskWindow;
		zendeskWindow.zE("messenger", "hide");
	}
}
