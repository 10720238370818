import {
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	signal
} from "@angular/core";
import { NavigationEnd, Router, Event } from "@angular/router";
import { AuthService } from "@core/services/auth/auth.service";
import { RouterSupportService } from "@core/services/router-support.service";
import { BusinessService } from "@data/services/business.service";
import { TranslateService } from "@ngx-translate/core";
import {
	combineLatest,
	filter,
	map,
	Observable,
	of,
	Subject,
	switchMap,
	takeUntil
} from "rxjs";
import { environment } from "@env";

import { ptBrLocale } from "ngx-bootstrap/locale";
import { LocalStorageService } from "@core/services/storage/local-storage.service";
import Hotjar from "@hotjar/browser";
import { AutomataBreadcrumbService } from "@shared/services/automata-breadcrumb.service";
import { PostMessageService } from "@shared/services/post-message.service";
import { OnboardingUserService } from "@data/services/onboarding-user.service";
import { DatadogService } from "@core/services/datadog.service";
import { ServiceWorkerService } from "@core/services/update/service-worker.service";
import { DashboardService } from "@data/services/dashboard.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
	private _onDestroy = new Subject<boolean>();

	protected showChecklist = signal(false);
	protected showWelcomeModal = signal(false);
	protected onboardingUserId = signal(0);

	@ViewChild("templateModal") public templateModal!: TemplateRef<unknown>;

	constructor(
		public authService: AuthService,
		private translate: TranslateService,
		private router: Router,
		public businessService: BusinessService,
		private dashboardService: DashboardService,
		private routerSupport: RouterSupportService,
		private localStorage: LocalStorageService,
		private automataBreadcrumbService: AutomataBreadcrumbService,
		private postMessageService: PostMessageService,
		private onboardingUserService: OnboardingUserService,
		private datadogService: DatadogService,
		private serviceWorkerService: ServiceWorkerService
	) {
		this.serviceWorkerService.initialize();
		this.translate.addLangs(["pt", "en"]);
		this.translate.setDefaultLang("pt");

		const browserLang = this.translate.getBrowserLang();
		this.translate.use(
			(/pt|en/.exec(browserLang ?? "") ? browserLang : "pt") ?? ""
		);
	}

	ngOnInit(): void {
		if (this.authService.isLoggedIn()) {
			this.initPrereleaseZendeskUser();
		}

		this.watchRouteEnd();
		this.peekActualUrl();
		this.initializeHotjar();
		this.initializeOnboardingGuide();
		this.handleNewDashboardPreRelease();
		this.automataBreadcrumbService.watch();
		this.postMessageService.listenMessage();
		this.datadogService.init();

		this.translate
			.get("GLOBAL.DATEPICKER.INVALID_DATE")
			.subscribe((translation) => {
				ptBrLocale.invalidDate = translation as string;
			});
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	private initPrereleaseZendeskUser(): void {
		const userEmail = this.authService.getUserLogin()?.email;
		if (userEmail) {
			this.authService
				.showPrereleaseZendesk(userEmail)
				.subscribe((response) => {
					this.authService.isPrereleaseZendeskUser.set(
						response.isPrereleaseZendeskUser
					);
				});
		}
	}

	private handleNewDashboardPreRelease(): void {
		const showPreRelease = this.localStorage.get<boolean>("showPreRelease");
		if (showPreRelease === null) {
			this.localStorage.set("showPreRelease", "true");
		}
		this.dashboardService.updateShowPreRelease(
			Boolean(
				JSON.parse(this.localStorage.get("showPreRelease") ?? "false")
			)
		);
	}

	private watchOnboardingGuide(): void {
		combineLatest([
			this.onboardingUserService.forceUpdateOnboardingUser$,
			this.authService.userLoggedIn$
		])
			.pipe(
				switchMap(([forcedUpdateUser, userLoggedIn]) => {
					if (!forcedUpdateUser && !userLoggedIn) {
						return of("NO_VALID_USER");
					}
					const userId = forcedUpdateUser
						? forcedUpdateUser.idUsuario
						: (userLoggedIn?.idUsuario ?? 0);
					this.onboardingUserId.set(userId);
					return this.onboardingUserService.getOnboardingUser(userId);
				}),
				takeUntil(this._onDestroy)
			)
			.subscribe((result) => {
				if (typeof result === "string") {
					return;
				}
				this.showChecklist.set(result.checklist);
				this.showWelcomeModal.set(result.showWelcomeModal);
			});
	}

	private initializeOnboardingGuide(): void {
		this.watchOnboardingGuide();
		const userLoggedIn = this.authService.getUserLogin();
		if (userLoggedIn) {
			this.onboardingUserId.set(userLoggedIn.idUsuario);
			this.onboardingUserService
				.getOnboardingUser(userLoggedIn.idUsuario)
				.subscribe(({ checklist, showWelcomeModal }) => {
					this.showChecklist.set(checklist);
					this.showWelcomeModal.set(showWelcomeModal);
				});
		}
	}

	private initializeHotjar() {
		if (environment.production) {
			const siteId = environment.HOTJAR_SITE_ID;
			const hotjarVersion = environment.HOTJAR_VERSION;

			Hotjar.init(siteId, hotjarVersion);
		}
	}

	private watchEvents(): Observable<Event> {
		return this.router.events.pipe(takeUntil(this._onDestroy));
	}

	private watchRouteEnd(): void {
		this.watchEvents()
			.pipe(
				filter(
					(event) =>
						event instanceof NavigationEnd &&
						event.url !== "/pages/auth/login"
				)
			)
			.subscribe(() => this.authService.setRouterEventDone());
	}

	private peekActualUrl(): void {
		this.watchEvents()
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((event) => (event as NavigationEnd).url)
			)
			.subscribe((url) => {
				this.routerSupport.isUrlSupport(url);
			});
	}
}
